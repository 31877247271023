<template>
  <header :class="getMenuOpened ? 'menu-opened':'menu-closed'">
    <div class="container-fluid can-inherit">
      <div class="header-inner">
        <ul class="socialmedia">

          <li>
            <a href="https://www.facebook.com/events/396729658986934" target="_blank">
              <svg-icon-facebook/>
            </a>
          </li>
          <li>
            <a href="https://www.instagram.com/festival.i.de.marktgass/" target="_blank">
              <svg-icon-instagram/>
            </a>
          </li>
          <li>
            <a href="/">
              <svg-logo-fisch/>
            </a>
          </li>

        </ul>

        <div class="nav-toggle" @click="toggleMenu">
          <div class="hamburger">
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
    </div>

    <div class="nav-bar">
      <ul class="menu">

        <li>
          <router-link to="/lineup" @click="closeMenu">Line Up</router-link>
        </li>

        <li>
          <router-link to="/timetable" @click="closeMenu">Timetable</router-link>
        </li>

        <li>
          <router-link to="/info" @click="closeMenu">Info</router-link>
        </li>

        <li>
          <a v-bind:href="this.$store.getters.getTicketLink" target="_blank" @click="closeMenu">Tickets</a>
        </li>

<!--        <li v-for="(link, index) in this.$store.getters.getMainMenu" :key="index">-->
<!--          <RouterLink :to="link.link" v-html="link.title" @click="closeMenu"/>-->
<!--        </li>-->
      </ul>
    </div>

  </header>
</template>

<script>
// import {cpFullStorageUrl} from "@/api/cockpit";

import SvgIconFacebook from "@/components/svg/svgIconFacebook";
import SvgIconInstagram from "@/components/svg/svgIconInstagram";
import SvgLogoFisch from "@/components/svg/svgLogoFisch";

export default {
  name: "PartHeader",
  components: {SvgLogoFisch, SvgIconInstagram, SvgIconFacebook},
  data() {
    return {
      menuOpened: false
    };
  },
  methods: {
    toggleMenu() {
      this.menuOpened = !this.menuOpened;
    },
    closeMenu() {
      this.menuOpened = false;
    }
  },
  computed: {
    getMenuOpened() {
      return this.menuOpened;
    }
  },
  created() {

  },
}
</script>

<style scoped>


header {
  height: var(--header-height);
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 11;
  background-color: var(--color-grey);
}

.header-inner {
  /*height: var(--header-height);*/
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--main-margin) 0;
}

ul.socialmedia {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  gap: 1.5rem;
}

ul.socialmedia > li > a svg {
  height: var(--hamburger-height);
}

ul.socialmedia > li > a svg path {
  fill: var(--color-1) !important;
}

header .nav-toggle {
  display: flex;
  align-items: center;
  /*height: var(--header-height);*/
}

header .nav-toggle .hamburger {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: var(--hamburger-width);
  height: var(--hamburger-height);
  cursor: pointer;
  transition: all ease var(--transition-time);
  z-index: 1;
}

header .nav-toggle .hamburger > div {
  width: 100%;
  height: var(--hamburger-slize-size);
  background-color: var(--color-1);
  display: block;
  position: absolute;
  transform-origin: left;
  transition: all ease var(--transition-time);
}

header .nav-toggle .hamburger > div:first-child {
  top: var(--hamburger-slize-size);
}

header .nav-toggle .hamburger > div:nth-child(3) {
  bottom: var(--hamburger-slize-size);
}

header.menu-opened .nav-toggle .hamburger > div:first-child {
  top: 0;
  transform: translateX(var(--hamburger-slize-size)) rotate(45deg);
}

header.menu-opened .nav-toggle .hamburger > div:nth-child(2) {
  transform: translatex(500%);
  opacity: 0;
}

header.menu-opened .nav-toggle .hamburger > div:nth-child(3) {
  bottom: 0;
  transform: translateX(var(--hamburger-slize-size)) rotate(-45deg);
}

.nav-bar {
  overflow: hidden;
  transition: all ease var(--transition-time);
  opacity: 0;
  background-color: var(--color-grey);
  height: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

header.menu-opened .nav-bar {
  opacity: 1;
height: calc(100vh - var(--header-height));
}

.nav-bar > ul.menu {
  padding: 0 0;
  margin: 4rem 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  }



.nav-bar > ul.menu > li {
  font-family: 'BebasNeue-Bold';
  font-size: 3rem;

}

.nav-bar > ul.menu > li a {
  text-decoration: none;
  color: var(--color-1);
  transition: all ease var(--transition-time);
}
.nav-bar > ul.menu > li a:hover {
  color: var(--color-2);
  /*letter-spacing: var(--letter-spacing);*/

}





/*font-family: 'BebasNeue-Bold';*/


/*ul.menu {*/
/*  top: 0;*/
/*  padding: 1.2rem 1.5rem;*/
/*}*/
/*ul.menu a {*/
/*  text-decoration: none;*/
/*  text-transform: uppercase;*/
/*  font-weight: 600;*/
/*  letter-spacing: 2px;*/
/*  position: relative;*/
/*}*/

/*ul.menu a:after {*/
/*  content: '';*/
/*  position: absolute;*/
/*  bottom: -10px;*/
/*  left: 0;*/
/*  width: 100%;*/
/*  border-bottom: 2px solid var(--color1);*/
/*  transition: all ease var(--transition-time);*/
/*  opacity: 0;*/
/*}*/
/* ul.menu li a:hover:after, ul.menu li a.active:after {*/
/*  bottom: -3px;*/
/*  border-bottom-color: var(--color2);*/
/*  opacity: 1;*/
/*}*/

</style>
