<template>
  <section class="wrapper">
    <h2 class="subtitle">ÖV-Anreise ist im Ticket inbegriffen</h2>
    <h3>ÖV-Partnerinnen</h3>
    <div class="partnerinlogo-wrapper">
      <a class="partnerinlogo" href="https://www.aargauverkehr.ch/" target="_blank">
        <SvgLogoAva/>
      </a>
      <a class="partnerinlogo" href="https://www.a-welle.ch/" target="_blank">
        <SvgLogoAwelle/>
      </a>
      <a class="partnerinlogo" href="https://www.valiant.ch/" target="_blank">
        <SvgLogoValiant/>
      </a>
      <a class="partnerinlogo" href="https://www.eifach.ch/" target="_blank">
        <SvgLogoEifach/>
      </a>
    </div>
  </section>
</template>

<script>
import SvgLogoAva from "@/components/svg/SvgLogoAva";
import SvgLogoAwelle from "@/components/svg/SvgLogoAwelle";
import SvgLogoValiant from "@/components/svg/SvgLogoValiant";
import SvgLogoEifach from "@/components/svg/SvgLogoEifach";


export default {
  name: "SectionParterin",
  components: {SvgLogoValiant, SvgLogoAwelle, SvgLogoAva, SvgLogoEifach}
}
</script>

<style scoped>
.wrapper {
  background-color: var(--color-2);
  padding: 2rem;
  box-sizing: border-box;
  text-align: center;
}

h2, h3 {
  /*font-size: 3rem;*/
  color: var(--color-3);
  margin-bottom: 1rem !important;
}

.partnerinlogo-wrapper {
  display: flex;
  justify-content: space-around;
  padding: 0 2rem;
}

svg {
  width: 150px;
  fill: var(--color-3);
  transition: all ease var(--transition-time);
}
svg:hover {
  fill: var(--color-1);
}

@media (max-width: 992px) {

  .partnerinlogo-wrapper {
    display: flex;
flex-direction: column;
    gap: 1rem;
  }

  svg {
    width: 200px;
  }
}

</style>
