<template>
  <footer>
    <SectionTicketlink/>
    <SectionLogo/>
    <SectionParterin/>

    <div class="container pt-1 pb-1">
      <ul class="menu">
        <li class="">
          <a href="https://www.facebook.com/events/396729658986934" target="_blank">
            <svg-icon-facebook/>
          </a>
        </li>

        <li v-for="(link, index) in this.$store.getters.getFooterMenu" :key="index">
          <router-link :to="link.link" v-html="link.title" />
        </li>

        <li>
          <a v-bind:href="this.$store.getters.getTicketLink" target="_blank">Tickets</a>
        </li>

        <li>
          <a href="https://www.instagram.com/festival.i.de.marktgass/" target="_blank">
            <svg-icon-instagram/>
          </a>
        </li>
      </ul>
    </div>
  </footer>
</template>

<script>
import SectionTicketlink from "@/components/SectionTicketlink";
import SectionLogo from "@/components/SectionLogo";
import SectionParterin from "@/components/SectionPartnerin";
import SvgIconFacebook from "@/components/svg/svgIconFacebook";
import SvgIconInstagram from "@/components/svg/svgIconInstagram";

export default {
  name: "PartFooter",
  components: {SvgIconInstagram, SvgIconFacebook, SectionParterin, SectionLogo, SectionTicketlink},
  // data() {
  // },
  computed: {

  },
  created() {


  },
}
</script>

<style scoped>
ul {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style: none;
  gap: 1.5rem;
}


ul > li {
  font-family: 'BebasNeue-Bold';
  font-size: 1rem;
  /*text-align: center;*/
}


ul > li a {
  text-decoration: none;
  color: var(--color-1);
  transition: all ease var(--transition-time);
}
ul > li a:hover {
  color: var(--color-2);
}


ul > li > a svg {
  height: 1rem;
}

ul > li > a svg path {
  fill: var(--color-1) !important;
}

@media (max-width: 576px) {
  ul {
  flex-direction: column;
    gap: 0.5rem;
  }
  ul > li {
    font-size: 1.3rem;
  }
}
</style>
