<template>
  <section class="wrapper">
    <router-link to="/">
    <svg id="Ebene_1" data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 550.2 262">
      <defs>

      </defs>
      <path class="cls-1" d="M435.9,3h-56.6v259h35.5V78.8l30.3,183.2h38.5l28.1-185.7v185.7h38.5V3h-56.6l-27.8,183.9L435.8,3h.1ZM291.6,40c6.4,0,11.5,1.8,15.2,5.5s5.5,10.2,5.5,19.6v134.7c0,9.4-1.8,15.9-5.5,19.6s-8.8,5.6-15.2,5.6h-22.9V40h22.9ZM228,262h64.4c20.5,0,35.7-5.4,45.7-16.3s15-26.8,15-47.7V67c0-21-5-36.9-15-47.7-10-10.9-25.2-16.3-45.7-16.3h-64.4v259ZM158.8,262h40.7V3h-40.7v259ZM84.6,42.2c0,13.3,11.6,23.8,24.5,23.8s24.5-12.2,24.5-27.2S116.2,0,90,0,26,31.3,26,89.5v2.4h-4.4c-7.5,0-15.3-.3-19.1,2.7-3.1,2-2.7,6.1-2,7.5h25.5v131c0,10.2-2.7,14-7.1,17-6.8,4.1-11.2,2.7-15.3,5.8-2.4,1.7-2.7,5.1-1.7,6.1h100.7c.7-1,.3-4.4-1.7-6.1-4.4-2.7-7.5-1.7-14-5.8-4.8-3.1-7.8-6.8-7.8-17V102.1h21.4c7.5,0,15,.3,19.1-2.7,2.7-2,2.4-6.5,2-7.5h-42.5c-11.9-9.5-24.5-26.9-24.5-47S72.3,8.5,89.6,8.5s19.4,4.8,22.5,9.9c-15,0-27.6,7.5-27.6,23.8"/>
    </svg>
    </router-link>
  </section>
</template>

<script>
export default {
  name: "SectionLogo"
}
</script>

<style scoped>
.wrapper {
  /*background-color: var(--color-grey);*/
  /*padding: 2rem;*/
  padding: 2rem;
  /*padding-top: 0;*/

  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;

  /*height: 30vw;*/
  /*max-height: 40vh;*/
  /*min-height: 25vh;*/
}
a:hover .cls-1 {
  fill: var(--color-2);
}
svg {
  max-height: 100%;
  max-width: 100%;
  height: 100%;
  width: 100%;
  width: 25rem;
}

.cls-1 {
  fill: # var(--color-1);
  stroke-width: 0px;
  transition: all ease var(--transition-time);
}
</style>
