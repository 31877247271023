<template>
  <div class="fischwrapper one d-none d-lg-block" ref="parallax1">
    <svg-logo-fisch/>
  </div>
<!--  <div class="fischwrapper two d-none d-lg-block" ref="parallax2">-->
<!--    <svg-logo-fisch/>-->
<!--  </div>-->


</template>

<script>
import SvgLogoFisch from "@/components/svg/svgLogoFisch";

export default {
  name: "PartFishParallax",
  components: {SvgLogoFisch},
  data() {
    return {
      lineup: [],
    };
  },
  methods: {
    parallaxHandleScroll() {
      let parallax1 = this.$refs.parallax1;
      // let parallax2 = this.$refs.parallax2;

      if(parallax1) {
        let scrollPosition = window.scrollY + window.innerHeight;
        let offset1 = (scrollPosition - window.outerHeight - 0) * -0.75; // Parallax-Geschwindigkeit
        // let offset2 = (scrollPosition - window.outerHeight - 700) * -1.5; // Parallax-Geschwindigkeit

        parallax1.style.transform = `translateY(${offset1}px)`;
        // parallax2.style.transform = `translateY(${offset2}px)`;
      }
    }
  },
  created() {

  },
  mounted() {
    this.parallaxHandleScroll();
    window.addEventListener('scroll', this.parallaxHandleScroll);

  },
}
</script>

<style scoped>
.fischwrapper {
  position: absolute;
  width: 14rem;
}
.fischwrapper.one {
  bottom: -30rem;
  right: 2rem;
}
.home .fischwrapper.one {
  bottom: -45rem;
}
.fischwrapper.two {
  bottom: 2rem;
  left: 2rem;
}
.fischwrapper .cls-1 {
  /*fill: var(--color-grey) !important;*/
  fill: var(--color-2) !important;
}
.fischwrapper svg {
  /*fill: var(--color-orange) !important;*/
  fill: var(--color-1) !important;
}
@media (min-height: 992px ) {
  .fischwrapper.one {
    bottom: -20rem;
  }
  .home .fischwrapper.one {
    bottom: -20rem;
  }
}
</style>
