<template>
  <svg id="Logo" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 283.5 74.6">
    <path class="st0" d="M254.9,9.1h13.6v13.7h13.5v11.3h-13.5v18.7c0,7.5,3,9.2,8.5,9.2s6.4-1.1,6.4-1.1v11.2s-2.2,1.8-8.4,1.8c-17,0-20.2-8.5-20.2-18V9.1ZM200.1,73h13.7v-27c0-7.1,3.2-12.5,10.3-12.5s9,3.8,9,11.7v27.9h13.6v-30.4c0-12.7-5.2-21.3-18.2-21.3s-11.8,2.4-15.1,7.7v-6.1h-13.4v50.1ZM168.1,21.3c-6.2,0-13,1.2-18.1,3.2v11.3c5-2.2,10.8-3.4,16.1-3.4,8.5,0,13,3.3,13,11-3.5-1.1-7.8-1.5-11.3-1.5-10.9,0-21.3,5.7-21.3,16.9s7.5,15.8,17.6,15.8,11.8-2.2,15.2-6.4l.4,4.9h12.2s0-29,0-29c0-15.1-8.9-22.7-23.7-22.7ZM168.4,64.2c-5.5.4-8.8-1.9-9-6-.2-4.6,4.4-6.7,9.4-7.1,6.6-.4,10,1.9,10.1,5.2.2,4-2.9,7.4-10.5,7.8ZM125.1,7.9c0,4.6,3.3,7.9,8,7.9s8.3-3.3,8.3-7.9-3.5-7.9-8.3-7.9-8,3.3-8,7.9M126.3,73h13.9V22.9h-13.9v50.1ZM104.1,73h13.7V1.3h-13.7v71.7ZM72.4,21.3c-4.1,0-8.8.7-12.2,1.7l-4.3,12.4c5-2.2,9.6-3,14.4-3,8.5,0,13,3.3,13,11-3.5-1.1-7.8-1.5-11.3-1.5-11,0-21.3,5.7-21.3,16.9,0,10.3,7.5,15.8,17.6,15.8s11.8-2.2,15.2-6.4l.4,4.9h12.2v-29c0-15.1-8.9-22.7-23.7-22.7ZM72.6,64.2c-5.5.4-8.8-1.9-9-6-.2-4.6,4.4-6.7,9.4-7.1,6.6-.4,10,1.9,10.1,5.2.2,4-2.9,7.4-10.5,7.8ZM19.3,73h14.2l19.5-50.1h-14.4l-11.9,35.8L15.2,22.9H0l19.3,50.1Z"/>
  </svg>
</template>

<script>
export default {
  name: "SvgLogoValiant"
}
</script>

<style scoped>
/*.st0 {*/
/*  fill: #7d8264;*/
/*}*/
</style>
