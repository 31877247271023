<template>
  <svg id="Ebene_1" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 850.4 242.5">

    <path class="st0" d="M127.9,176.1h33.5c-4.9,18.7-14.3,33-28.1,42.8-13.8,10.3-31.3,15.4-52.2,15.4s-45.3-8.3-59.6-24.8C7.1,193.5,0,170.5,0,140.6s6.9-50.9,20.8-67c14.3-16.5,34.1-24.8,59.6-24.8s47.6,8,62.3,24.1c14.3,16.5,21.4,39.5,21.4,69v7.4c0,2.2-.2,3.8-.7,4.7H47.5c.4,13.9,3.8,24.1,10.1,30.8,5.8,7.1,14.5,10.7,26.1,10.7s14.1-1.6,19.4-4.7c1.3-.4,2.6-1.2,3.7-2.3,1.1-1.1,2.3-2.4,3.7-3.7.4-1.3,2.1-3.1,5-5.4,2.9-2.2,7-3.3,12.4-3.3M47.5,123.9h68.3c-.9-12-4-21-9.4-26.8-5.8-6.2-14.1-9.4-24.8-9.4s-17.9,3.1-24.1,9.4c-6.3,6.3-9.6,15.2-10.1,26.8"/>
    <path class="st0" d="M172,.7h24.1c6.2,0,11.6,2.2,16.1,6.7,4.5,4.5,6.7,9.8,6.7,16.1v14.1h-46.9V.7ZM172,52.9h24.1c6.2,0,11.6,2.2,16.1,6.7,4.5,4.5,6.7,9.8,6.7,16.1v155.3h-46.9V52.9Z"/>
    <path class="st0" d="M271.6,96.5h6.7c5.8,0,10.9-2.1,15.4-6.4,4.4-4.2,6.7-9.7,6.7-16.4v-10h-28.8v-12c0-5.3,1.1-8.9,3.4-10.7,2.7-1.8,7.1-2.7,13.4-2.7h12.1V1.3c-2.7-.4-6.5-.6-11.4-.6C286.3.2,282.8,0,278.3,0,260,0,246.8,3.8,238.8,11.4c-8.5,8-12.7,20.1-12.7,36.2v123.4h45.5v-74.5Z"/>
    <path class="st0" d="M344.5,162.1c3.6-3.6,9.4-6.5,17.4-8.7l15.4-3.3c11.2-2.7,19.8-5.6,26.1-8.7v16.8c0,4.7-.5,8.9-1.5,12.9h44.6c.3-3.4.5-7.1.5-10.9v-60.9c0-16.1-6.5-28.6-19.4-37.5-12.5-8.5-31.3-12.7-56.3-12.7s-39.5,5.1-51.6,15.4c-10.7,8.9-17,20.3-18.8,34.2h44.9c2.2-4.9,5.8-8.3,10.7-10,4.9-1.8,10.5-2.7,16.7-2.7s12.2,1,19.1,3c6.9,2,10.4,6.4,10.4,13.1s-9.8,14.7-29.5,17.4c-4,.5-7.6,1-10.7,1.7-3.1.7-5.8,1-8,1-17,2.7-30.9,8.1-41.9,16.1-9.5,7-14.8,18-16.1,33h44c.8-2.9,2.1-5.9,3.9-8.8"/>
    <path class="st0" d="M496.9,141.3c0-20.5,4-35.3,12.1-44.2,6.3-6.2,14.3-9.4,24.1-9.4s19,3.1,24.8,9.4c2.2,2.2,4.5,5.6,6.7,10.1h46.9c-3.6-12.9-9.4-24.3-17.4-34.2-14.7-16.1-35.5-24.1-62.3-24.1s-45.3,8.3-59.6,24.8c-13.9,16.1-20.8,38.4-20.8,67s1,21.1,2.9,30.3h46.7c-2.7-7.8-4.1-17.7-4.1-29.6"/>
    <path class="st0" d="M665.6,139.3c0-15.6,3.4-27.9,10.1-36.8,6.7-8.9,16.3-13.4,28.8-13.4s20.8,4.7,27.5,14.1c4,5.8,6.6,11.5,7.7,17.1,1.1,5.6,1.7,11.7,1.7,18.5v32.3h44.9v-43.7c0-20.5-4.3-36.6-12.7-48.2-8.5-11.6-19-20.1-31.5-25.5-13.4-5.3-27.5-6.2-42.2-2.7-14.7,3.6-26.1,12.1-34.2,25.4V23.4c0-6.2-2.2-11.6-6.7-16.1-4.5-4.5-9.8-6.7-16.1-6.7h-22.8v170.2h45.5v-31.6Z"/>
    <path class="st0" d="M850.4,127.4h-29.3c-11.8,0-17.7,5.5-17.7,16.4v27.1h47v-43.5Z"/>
    <path class="st0" d="M842.9,109.7L848.7,0h-23.8c-6.8,0-12.6,2.2-17.4,6.6-4.8,4.4-7.2,9.8-7.2,16.1l10.5,87h32Z"/>
    <g>
      <path class="st0" d="M244.1,202.4c-3,0-6.1,1.2-7.9,3.6l-3.5-3.4c3.1-3.4,7.7-5.2,11.9-5.2,6.4,0,12.6,3.5,12.6,10.8v21.6c-4.6,1.6-9.3,2.3-13.8,2.3-6.7,0-11.9-4.4-11.9-11.5,0-8,6.5-10.8,13.3-10.8s5.2.7,7.3,1.9v-3.5c0-3.9-3.9-5.7-7.9-5.7ZM252,216c-2.1-.7-4.8-1.4-7.5-1.4-4.2,0-7.9,1-8,5.8,0,4.3,3,6.9,7,7,2.8,0,5.7,0,8.5-.6v-10.8Z"/>
      <path class="st0" d="M279,231.4c-7.3,0-11.3-4.1-11.3-10.9v-34.4h5.2v34.4c0,3.7,1.9,5.7,6.2,5.7v5.2Z"/>
      <path class="st0" d="M297.5,231.4c-7.3,0-11.3-4.1-11.3-10.9v-34.4h5.2v34.4c0,3.7,1.9,5.7,6.2,5.7v5.2Z"/>
      <path class="st0" d="M309.1,220.9c0,4.1,3.8,6,7.7,6s6.3-1.5,7.6-4.6l4.6,2.3c-2.3,4.9-7.4,7.4-12.3,7.4s-13-3.7-13-11.1v-12.6c0-7.5,6.5-11.1,13-11.1s12.8,3.6,12.8,11.1v9h-20.5v3.7ZM316.8,202.2c-3.9,0-7.8,1.9-7.7,6v4.6h15.6v-4.8c0-3.9-4.1-5.9-7.9-5.9Z"/>
      <path class="st0" d="M339.8,222.2c2.3,3.4,6.1,4.8,9.5,4.6,3,0,6.3-1.5,6.2-5,0-3.6-3.6-4.5-6.6-5.1h0c-5.5-1-11.7-2.6-11.7-9.8s6.6-9.7,12.1-9.7,7.3,1.3,10.1,4l-3.4,3.9c-2.1-2.1-4.4-2.9-6.7-2.9s-7,.9-7,4.6c0,3.4,4.6,4.3,7.5,4.8,5.2,1,10.7,3.3,10.8,10.1,0,6.6-5.5,9.5-11.3,9.7-5.2.1-10.2-1.8-13.4-7l3.9-2.4Z"/>
      <path class="st0" d="M399.3,190.2c-4.6,0-7.3,2.3-7.3,6.1v6.2h6.8v5.2h-6.8v23.5h-5.2v-34.8c0-7.4,5.5-11.1,12.4-11.2v5Z"/>
      <path class="st0" d="M432.3,229.3c-6.3,2.2-10.2,2.4-13,2.4-6.3,0-12.6-3.7-12.6-11.1v-23h5.2v23.1c0,3.9,3.7,6.1,7.5,6.1,3,0,5.6-.3,7.9-1.1v-28.1h5.2v31.6ZM411.7,191.9c0,1.7,1.3,3,3,3s3-1.3,3-3-1.4-3-3-3-3,1.4-3,3ZM421.4,191.9c0,1.7,1.2,3,3,3s3-1.3,3-3-1.3-3-3-3-3,1.4-3,3Z"/>
      <path class="st0" d="M457.2,197.7v5.3c-2.4-.4-3-.6-5.1-.1-2.6.7-5.2,2.6-5.2,5.7v22.6h-5.2v-22.6c0-6,4.3-9.8,9.5-10.8,1.8-.5,3.9-.5,5.9,0Z"/>
      <path class="st0" d="M486.6,185.7c2.1,0,3.6,1.5,3.6,3.6s-1.5,3.6-3.6,3.6-3.6-1.6-3.6-3.6,1.6-3.6,3.6-3.6ZM484.1,197.6v33.6h5.2v-33.6h-5.2Z"/>
      <path class="st0" d="M505.7,186.2v13.5c2.1-1.2,5.1-1.9,7.7-1.9,6.4-.1,12.8,3.9,12.8,11.1v22.3h-5.1v-22.4c0-3.9-3.9-5.8-7.7-5.9-2.6,0-5.2.4-7.7,1.4v26.8h-5.2v-45h5.2Z"/>
      <path class="st0" d="M551.6,197.7v5.3c-2.4-.4-3-.6-5.1-.1-2.6.7-5.2,2.6-5.2,5.7v22.6h-5.2v-22.6c0-6,4.3-9.8,9.5-10.8,1.8-.5,3.9-.5,5.9,0Z"/>
      <path class="st0" d="M563.5,220.9c0,4.1,3.8,6,7.7,6s6.3-1.5,7.6-4.6l4.6,2.3c-2.3,4.9-7.4,7.4-12.3,7.4s-13-3.7-13-11.1v-12.6c0-7.5,6.5-11.1,13-11.1s12.8,3.6,12.8,11.1v9h-20.5v3.7ZM571.2,202.2c-3.9,0-7.8,1.9-7.7,6v4.6h15.6v-4.8c0-3.9-4.1-5.9-7.9-5.9Z"/>
      <path class="st0" d="M624.9,231.2h-6.2l-10.1-33.4h5.4l6.3,20.9,1.2,7.4h.5l.9-7.4c1.7-7,3.4-14,5.1-20.9h5.7l5.2,20.9,1,7.4h.5l1.1-7.4,6.3-20.9h5.3l-10,33.4h-6.2l-5.9-24.2-5.9,24.2Z"/>
      <path class="st0" d="M664.7,220.9c0,4.1,3.8,6,7.7,6s6.3-1.5,7.6-4.6l4.6,2.3c-2.3,4.9-7.4,7.4-12.3,7.4s-13-3.7-13-11.1v-12.6c0-7.5,6.5-11.1,13-11.1s12.8,3.6,12.8,11.1v9h-20.5v3.7ZM672.5,202.2c-3.9,0-7.8,1.9-7.7,6v4.6h15.6v-4.8c0-3.9-4.1-5.9-7.9-5.9Z"/>
      <path class="st0" d="M709.4,197.7v5.3c-2.4-.4-3-.6-5.1-.1-2.6.7-5.2,2.6-5.2,5.7v22.6h-5.2v-22.6c0-6,4.3-9.8,9.5-10.8,1.8-.5,3.9-.5,5.9,0Z"/>
      <path class="st0" d="M721.4,186.1v13.2c2.5-1.7,5-2.1,7.7-2.1,6.5,0,13,3.9,13,11.2v12.5c0,7.4-6.4,11-12.8,11.1-4.3,0-8.7-.9-13.1-2.4v-43.5h5.2ZM721.4,226.2c2.5.8,5.3,1,7.9,1,3.8,0,7.6-2.3,7.5-6.3v-12.6c0-3.9-3.8-5.9-7.7-5.9-2.5,0-5.3.5-7.8,1.5v22.4Z"/>
      <path class="st0" d="M777.4,229.3c-6.3,2.2-10.2,2.4-13,2.4-6.3,0-12.6-3.7-12.6-11.1v-23h5.2v23.1c0,3.9,3.7,6.1,7.5,6.1,3,0,5.6-.3,7.9-1.1v-28.1h5.2v31.6Z"/>
      <path class="st0" d="M787.7,200.6c4.3-1.5,8.8-2.8,13-2.8,6.3.1,12.6,3.5,12.6,11v22.4h-5.1v-22.4c0-4.1-3.9-6-7.5-6s-6,.8-7.9,1.5v27h-5.2v-30.6Z"/>
      <path class="st0" d="M827.6,234.5c1.4,1.8,5,3.2,7.9,3.2s8-1.9,8-6v-1.9c-2.2,1.4-5,1.7-7.6,1.7-6.4,0-12.8-3.9-12.8-11.1v-12.4c0-7.3,6.3-11,12.6-11s6.1.6,9,1.4c1.1.3,3,1,4,1.2v31.9c0,7.5-6.3,10.8-12.6,10.8s-8.8-1.7-11.7-4.9l3.4-3.1ZM843.5,203.5c-1.9-.7-4.9-1.5-7.9-1.5s-7.5,2.2-7.4,6.2v12.5c0,3.9,3.7,6,7.5,6.1,2.6,0,5.2-.5,7.7-1.5v-21.8Z"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: "SvgLogoValiant"
}
</script>

<style scoped>

/*.st0 {*/
/*  fill: #7d8264;*/
/*}*/

</style>
